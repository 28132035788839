import React from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";
import BgImage from 'gatsby-background-image-es5';
import Container from "../Container/Container";
import { Button } from '../../components/Utils/Button';

const SeeExamplesSection = styled.section`
    min-height: 300px;
    text-align: center;
    
    & > div {
        padding: 92px 0;
        
        &::before, &::after {
            filter: brightness(.75);
        }
    }
    
    h2 {
        font-size: 36px;
        color: white;
        margin-bottom: 64px;
        
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
`;

const SeeExamples = () => {

    const data = useStaticQuery(graphql`
        query {
            file(name: {eq: "see-examples"}) {
                childImageSharp {
                    fluid (maxHeight: 350, maxWidth: 900, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <SeeExamplesSection>
            <BgImage fluid={data.file.childImageSharp.fluid} >
                <Container>
                    <h2>W praktyce, dzięki aplikacji MowaDruku, zyskujecie Państwo niemal nieograniczone możliwości, które prowadzą do zbudowania niemożliwej do tej pory interakcji z Klientem.</h2>
                    <Button blue>zobacz przykład użycia</Button>
                </Container>
            </BgImage>
        </SeeExamplesSection>
    )
};

export default SeeExamples;