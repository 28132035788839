import React from 'react';
import styled from 'styled-components';
import Container from "../Container/Container";
import { SectionHeader } from "../Typography/SectionHeader";
import Blob1 from '../../../static/blob1.svg'
import Blob2 from '../../../static/blob2.svg'
import Blob3 from '../../../static/blob3.svg'

const WorkingOfAppSection = styled.section`
    text-align: center;
    margin: 0 auto;
    padding: 92px 0;
`;

const Step = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 50%;
    margin: 92px auto;
    
    .svg_wrapper {
        position: relative;
        font-size: 3rem;
        margin-right: 72px;
        
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: -1;
        }
    }
    
    p {
        margin: 0;
        text-align: left;
    }
    
    @media (max-width: 768px) {
        width: 100%;
        margin: 48px 0;
        
        .svg_wrapper {
            margin-right: 36px;
            font-size: 1.5rem;
            
            svg {
                transform: translate(-50%,-50%) scale(.5);
            }
        }
    }
`;

const WorkingOfApp = () => {

    const steps = [
        {
            number: '1.',
            text: 'Klient uruchamia aplikację na swoim telefonie i przykładą ją do produktu',
            blob: <Blob1/>
        },
        {
            number: '2.',
            text: 'Zostaje wyświetlony materiał promocyjny w postaci filmu, animacji bądź też tekstu',
            blob: <Blob2/>
        },
        {
            number: '3.',
            text: 'Zyskujesz nieosiągalny do tej pory sposób na reklamowanie swojego produktu!',
            blob: <Blob3/>
        },
    ];

    return (
        <WorkingOfAppSection>
            <Container>
                <SectionHeader>Działanie aplikacji jest proste</SectionHeader>
                {steps.map(step => (
                    <Step key={step.number}>
                        <div className="svg_wrapper">
                            {step.number}
                            {step.blob}
                        </div>
                        <p>
                            {step.text}
                        </p>
                    </Step>
                ))}

            </Container>
        </WorkingOfAppSection>
    )
};

export default WorkingOfApp;