import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
// import gsap from 'gsap';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Container from "../Container/Container";
import { Button } from '../../components/Utils/Button'
import {SectionHeader} from "../Typography/SectionHeader";

const mobilePadding = css`
  @media (max-width: 768px) {
    padding: 50px 5px;
  }
`;

const SectionContact = styled.section`
  ${mobilePadding};
  display: grid;
  place-items: center;
  min-height: calc(100vh - 327px);
  max-width: 1024px;
  margin: 0 auto;
  padding: 92px 0;
  
  .thanks {
      display: grid;
      place-items: center;
      opacity: 0;
  }
  
  p {
      text-align: center;
      margin-bottom: 50px;
  }
`;

const ContentWrapper = styled.div`
  grid-column-gap: 60px;
  width: 100%;
  padding: 52px;
  border-radius: 50px;
  margin-top: 92px;
  box-shadow: 0 0 45px -15px rgba(0,0,0,0.25);
  
  .checkbox_wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      
      input {          
          width: auto;
      }
      
      label {
          text-align: left;
          padding-left: 15px;
          display: block;
          color: #A5A5A5;
          font-size: 75%;
      }
  }
  
  h4 {
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 32px;
      text-align: center;
      font-size: 150%;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 55px 15px;
  }
`;

const StyledForm = styled.form`
  text-align: right;
  margin-bottom: 0;
  
  input, textarea {
    width: 100%;
    background: rgba(192,192,192,0.10);
    border: 1px solid #E3E3E3;
    color: var(--textNormal);
    padding: 8px 10px;
    border-radius: 5px;
    
    &:focus {
      //outline: none;
      //box-shadow: 0 0 5px 2px #1589BA;
    }
  }
  
  ul {
    list-style: none;
    margin: 0;
    
    li {
      display: flex;
      flex-direction: column;
    }
  }
`;

const UserSumbmission = styled.div`
  display: block;
`;

const NameAndMail = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 10px;
  
  @media (max-width: 762px) {
   
    & > div:first-child {
      margin-bottom: 10px;
    }
  }
`;

const Message = styled.div`
  margin-top: 30px;
  
  @media (max-width: 762px) {
    margin-top: 20px;
  }  
`;

const InputWrapper = styled.div`
  border-radius: 15px;
  position: relative;
  
  label {
    position: absolute;
    top: 8px;
    left: 10px;
    transform-origin: left;
    transition: transform .3s ease-in-out;
    color: #A5A5A5;
    cursor: text;
  }
  
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus + label,
  textarea:not(:placeholder-shown) ~ label {
    border: none;
    color: #868686;
    transform: translate(-9px, -31px);
    font-size: 60%;
  }
`;

const Contact = () => {
    //
    const { handleSubmit, register, errors } = useForm();
    //
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState("");
    const [formSent, setFormSent] = useState();
    //
    // function submitForm() {
    //     const functionPath = `/.netlify/functions/processContactForm`;
    //     const functionOptions = {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json;charset=UTF-8'
    //         },
    //         body: JSON.stringify({
    //             name,
    //             email,
    //             msg,
    //             locale
    //         }),
    //     };
    //
    //     fetch(functionPath, functionOptions)
    //         .then(response => console.log(response))
    //         .then(data=>{
    //             setFormSent('sent')
    //             return data;
    //         })
    //         .catch(err => {
    //             console.error('There was an ERROR!!!',err)
    //         });
    //
    //     setEmail('');
    //     setMsg('');
    //     setFormSent('sending');
    //
    // }

    // useEffect(() => {
    //     gsap.fromTo('.contact-container', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5});
    //     gsap.fromTo('.thanks', {autoAlpha: 0}, {autoAlpha: 1, duration: 1.5});
    // }, [formSent])

    const formFieldRef = useRef();

            return (
                <SectionContact>
                    <>
                        <>
                            <SectionHeader>Masz pytanie? Odezwij się</SectionHeader>
                            <ContentWrapper>
                                <h4>Formularz kontaktowy</h4>
                                <StyledForm name="contact">
                                    <UserSumbmission>
                                        <NameAndMail>
                                            <InputWrapper>
                                                <input
                                                    type="text"
                                                    placeholder=" "
                                                    name="name"
                                                    id="name"
                                                    onChange={e => setName(e.target.value)}
                                                    ref={register({
                                                        required: true
                                                    }, formFieldRef)}
                                                />
                                                <label htmlFor="name">Imię</label>
                                            </InputWrapper>
                                            <InputWrapper>
                                                <input
                                                    type="email"
                                                    placeholder=" "
                                                    name="email"
                                                    id="email"
                                                    onChange={e => setEmail(e.target.value)}
                                                    ref={
                                                        register({
                                                            required: true,
                                                            pattern: {
                                                                value: /\S+@\S+\.\S+/,
                                                                message: `Wrong email format`
                                                            }}, formFieldRef)}
                                                />
                                                <label htmlFor="email">Adres e-mail</label>
                                            </InputWrapper>
                                        </NameAndMail>
                                        <Message>
                                            <InputWrapper>
                                                <textarea
                                                    name="message"
                                                    placeholder=" "
                                                    id="message"
                                                    rows="5"
                                                    onChange={e => setMsg(e.target.value)}
                                                    ref={register({
                                                        required: true
                                                    }, formFieldRef)}
                                                />
                                                <label htmlFor="message">Wiadomość</label>
                                            </InputWrapper>
                                        </Message>
                                    </UserSumbmission>
                                    <div className="checkbox_wrapper">
                                        <input
                                            type="checkbox"
                                            id="consent"
                                            name="consent"
                                            ref={register({ required: true})}
                                        />
                                        <label className="small" htmlFor="consent">
                                            Wyrażam zgodę na przetwarzanie moich danych osobwych w celu obsługi tego formularza przez firmę MowaDruku sp. z o.o.
                                        </label>
                                        <ErrorMessage
                                            errors={errors}
                                            name="consent"
                                            message="Wyrażenie zgody jest niezbędne do przesłania formularza."
                                            render={({ message}) => <small>{message}</small>}
                                        />
                                    </div>
                                    <Button
                                        // onClick={handleSubmit(submitForm)}
                                    >
                                        Wyślij
                                    </Button>
                                </StyledForm>
                            </ContentWrapper>
                        </>
                    </>
                </SectionContact>
            );
};

export default Contact;

