import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";
import STLViewer from 'stl-viewer'
import Image from 'gatsby-image'
import Container from "../Container/Container";

const height = '550px';
const imgIndent = 33;

const HeroSection =  styled.section`
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    background-color: var(--heroBgColor);
    height: ${height};
    
    h1, h2 {
        font-weight: normal;
    }
    
    .grid {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 2fr;
        position: relative;
        padding: 62px 0;
        grid-gap: 48px;
        
        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }
    
    .textWrapper {
        display: grid;
        place-items: center;
        color: white;
        
        p {
            margin: 0 auto;
            color: white;
            font-size: 2rem;       
        }
    }
    
    @media (max-width: 768px) {
        height: 100%;
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-left: ${imgIndent}vw;
    position: absolute;
    
    .gatsby-image-wrapper {
        height: ${height};
        
        img {
            width: calc(100vw - ${imgIndent}vw)!important;
            border-radius: 50px 0 0 50px;
            filter: brightness(0.45);
            height: ${height};
        }
    }
    
    @media (max-width: 768px) {
        padding-left: unset;
        
        .gatsby-image-wrapper img {
            width: 100%!important;
            border-radius: 0;
            height: 100%;
        }
        
        .gatsby-image-wrapper {
            height: 100%;
        }
    }
`;

const BgImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    //filter: brightness(.45);
    border-radius: 50px 0 0 50px;  
    
    & > div {
        width: 100%;
        height: 100%; 
        
        &::before, &::after {
            width: calc(100vw - ${imgIndent}vw);
        }
    }
`;

const Logo3DWrapper = styled.div`
    //position: absolute;
    top: 50%;
    left: 5%; 
    border: 2px solid black;
    border-radius: 50px;
    width: min-content;
    
    canvas {
        border-radius: 50px;
    }
`;

const Hero = () => {

    const canvaRef = useRef();

    useEffect(() => {
    }, []);

    const data = useStaticQuery(graphql`
        query ParallaxSectionQuery {
            file(name: {eq: "hero_image"}) {
                childImageSharp {
                    fluid (maxHeight: 350, maxWidth: 900, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <HeroSection>
            <ImageWrapper>
                <BgImageWrapper>
                    <Image fluid={data.file.childImageSharp.fluid}/>
                </BgImageWrapper>
            </ImageWrapper>
            <Container>
                <div className="grid">
                    <Logo3DWrapper>
                        <STLViewer
                            ref={canvaRef}
                            model='https://res.cloudinary.com/panodstrony-pl/raw/upload/v1614592090/Camera_Controls_kbl2xt.stl'
                            width={350}
                            height={350}
                            modelColor='#E9E9E9'
                            backgroundColor="#E9E9E9"
                            lightColor="#fff"
                            cameraX={100}
                            cameraY={100}
                            cameraZ={500}
                            lights={[1,1,1]}
                            rotationSpeeds={[0.005, 0, 0.005]}
                            rotate={true}
                            orbitControls={true}
                        />
                    </Logo3DWrapper>
                    <div className="textWrapper">
                        <h1>Witamy w świecie rozszerzonej rzeczywistości <b>AR!</b></h1>
                        <h2>Technologia AR (Augmented Reality) jest  uzupełnienie naszego świata realnego o pewne wirtualne elementy, które sprawiają że nasze możliwości oddziaływania na postrzeganie wielu rzeczy staje się znacząco bogatsze.</h2>
                    </div>
                </div>

            </Container>
        </HeroSection>
    )
};

export default Hero;