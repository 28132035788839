import React from 'react';
import styled from 'styled-components';
import Container from "../Container/Container";
import WomanInBlob from '../../../static/woman-in-blob.svg';
import ManInBlob from '../../../static/man-in-blob.svg';

const UsecasesOfAppSection = styled.section`
    padding: 92px 0;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: ${({ reverted }) => reverted ? '2fr 1fr' : '1fr 2fr'};
    grid-gap: 48px;
    align-items: center;
    margin-bottom: 24px;
    
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        grid-gap: 12px;
        
        svg {
            width: 50%;
            height: auto;
        }
        
        p {
            order: 2;
        }
    }   
`;

const UsecasesOfApp = () => {

    return (
        <UsecasesOfAppSection>
            <Container>
                <GridWrapper>
                    <WomanInBlob/>
                    <p>Aplikację MowaDruku można wykorzystać również do <b>ożywienia</b> wszystkich materiałów drukowanych (np. wizytówek, ulotek, zaproszeń, folderów, plakatów, książek, kalendarzy)</p>
                </GridWrapper>
                <GridWrapper reverted>
                    <p>Pozwala to wzbudzić <b>zainteresowanie</b> konsumentów, np. komunikatem o rozpoczynającym się konkursie, promocją tego lub innych produktów, informacjami o atrakcyjności regionu, w którym powstają produkty Państwa firmy, czy też akcją charytatywną.</p>
                    <ManInBlob/>
                </GridWrapper>
            </Container>
        </UsecasesOfAppSection>
    )
};

export default UsecasesOfApp;