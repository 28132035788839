import styled from 'styled-components';

export const Button = styled.button`
    background: ${({ blue}) =>  blue? 'var(--blue)' : 'var(--black)'};
    border-radius: 50px;
    color: var(--white);
    padding: 12px 72px;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    
    @media (max-width: 768px) {
        font-size: .7rem;
    }
`;