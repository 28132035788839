import styled from 'styled-components';

export const SectionHeader = styled.h3`
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    color: ${({ white }) => white ? 'var(--white)' : 'var(--black)'};
    margin: ${({ withMargin }) => withMargin ? '32px 0' : '0'};
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
 `;