import React from 'react';
import styled from 'styled-components';
import Container from "../Container/Container";
import { SectionHeader } from "../Typography/SectionHeader";
import DownloadBg from '../../../static/download_bg.svg';
import DownloadPhone from '../../../static/download_phone.svg'
import {graphql, useStaticQuery} from "gatsby";
import Image from 'gatsby-image';

const DownloadSection = styled.section`
    position: relative;
`;

const StyledDownloadBg = styled(DownloadBg)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    position: relative;
    z-index: 1;
    place-items: center;
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        
        .one {
            order: 2;
        }
        
        .two {
            margin-bottom: 32px;
            text-align: center;
        }
        
        svg {
            width: 35%;
            height: auto;
        }
    }
    
`;

const StyledDownloadPhone = styled(DownloadPhone)`
    transform: rotate(13deg);
`;

const DownloadBadges = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    a {
        transition: transform .3s ease-in-out;
        cursor: pointer;
    }
    
    a:hover {
        transform: skew(-5deg);
    }
    
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Downlaod = () => {

    const data = useStaticQuery(graphql`
        query DownloadQuery {
            appstore: file(name: {eq: "pobierz-z-appstore"}) {
                childImageSharp {
                    fixed (height: 75) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            googleplay: file(name: {eq: "pobierz-z-googleplay"}) {
                childImageSharp {
                    fixed (height: 75) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    return (
        <DownloadSection>
            <StyledDownloadBg/>
            <Container>
                <GridWrapper>
                    <div className="one">
                        <SectionHeader white>
                            Pobierz i sprawdź <br/> za darmo!
                        </SectionHeader>
                        <DownloadBadges>
                            <a href="https://mowadruku.netlify.app">
                                <Image fixed={data.appstore.childImageSharp.fixed}/>
                            </a>
                            <a href="https://mowadruku.netlify.app">
                                <Image fixed={data.googleplay.childImageSharp.fixed}/>
                            </a>
                        </DownloadBadges>
                    </div>
                    <div className="two">
                        <StyledDownloadPhone/>
                    </div>
                </GridWrapper>
            </Container>
        </DownloadSection>
    )
};

export default Downlaod;