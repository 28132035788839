import React from 'react';
import styled from 'styled-components';
import EmailIcon from '../../../static/email.svg';
import Facebook from '../../../static/facebook.svg'
import YouTube from '../../../static/youtube.svg'
import Instagram from '../../../static/instagram.svg'
import Container from "../Container/Container";
import {SectionHeader} from "../Typography/SectionHeader";

const FooterSection = styled.section`
    background: var(--black);
    color: #d1d1d1;
    text-align: center;
    padding: 92px 0;
    
    .mail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 48px 0;
        
        span {
            color: #6c6c6c;
        }
        
        svg {
            margin-right: 8px;
        }
    }
    .sm_icons {
        display: flex;
        justify-content: center;
        
        svg {
            margin: 0 16px;
        }
    }
`;

const Footer = () => {


    return (
        <FooterSection>
            <Container>
                <SectionHeader white>Kontakt</SectionHeader>
                <div className="mail">
                    <EmailIcon/> <span>info@mowadruku.pl</span>
                </div>
                <div className="sm_icons">
                    <Facebook/>
                    <YouTube/>
                    <Instagram/>
                </div>
            </Container>
        </FooterSection>
    )
};

export default Footer;