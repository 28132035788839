import React from "react"
import SEO from "../components/seo";
import Hero from '../components/IndexPage/Hero';
import SubHero from '../components/IndexPage/SubHero';
import WorkingOfApp from '../components/IndexPage/WorkingOfApp';
import Download from '../components/IndexPage/Download';
import UsecasesOfApp from '../components/IndexPage/UsecasesOfApp';
import SeeExamples from '../components/IndexPage/SeeExamples';
import Contact from '../components/IndexPage/Contact';
import Footer from '../components/IndexPage/Footer';

const IndexPage = () => {

  return (
  <>
    <SEO title="MowaDruku - interaktywne etykiety" />
    <Hero/>
    <SubHero/>
    <WorkingOfApp/>
    <Download/>
    <UsecasesOfApp/>
    <SeeExamples/>
    <Contact/>
    <Footer/>
  </>
  )};

export default IndexPage
