import React from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";
import SvgBackground from '../../../static/subhero_bg.svg';
import Image from 'gatsby-image';
import Container from "../Container/Container";
import PhoneGraphic from '../../../static/subhero_graphic.svg';

const SubHeroSection = styled.section`
    position: relative;
    margin-top: -50px;
    
    h2 {
        font-weight: normal;
    }
    
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
`;

const GridWrapper = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    place-items: center;
    grid-gap: 92px;
    
    p {
        position: relative;
        font-size: 1.7rem;
    }
    
    svg {
        position: static;
        height: 100%;
        width: 100%;
    }
    
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 24px;
        
        h2 {
            order: 2;
        }
        
        .svg {
            text-align: center;
            
            svg {
                width: 25%;
            }
        }
    }
`;



const SubHero = () => {

    const data = useStaticQuery(graphql`
        query SubHeroGraphic {
            file(name: {eq: "subhero_graphic"}) {
                childImageSharp {
                    fluid (maxHeight: 400, maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `);

    return (
        <SubHeroSection>
            <SvgBackground/>
            <Container>
                <GridWrapper>
                    <h2><b>MowaDruku</b> to narzędzie, które w rękach kreatywnych pracowników Działu Marketingu może stać się swoistym komunikatorem w interakcji z Klientem</h2>
                     <div className="svg">
                         <PhoneGraphic/>
                     </div>
                </GridWrapper>
            </Container>

        </SubHeroSection>
    )
};

export default SubHero;